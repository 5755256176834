
import { Component, Watch, Vue } from 'vue-property-decorator';
import {
  GtMenu,
  NewStructureModal,
  OldStructureModal,
  CheckoutNewSeriesModal,
  GeneratorMobileCurtain
} from './components';
import { namespace } from 'vuex-class';
import { MenuModel, NewSeriesMap } from '@/store/layout-store/types';
import { SET_MENU, SET_STRUCTURE_STATUS } from '@/store/list';
import { StructureStatus, StructureStatusName } from '@/models';
import { SET_IS_USER_LOGGED } from '@/store/auth-store/mutations';
import { CookieName } from '@/core/cookies';
import { RouteName } from '@/router/models';

const AppStore = namespace('AppStore');
const AuthStore = namespace('AuthStore');
const LayoutStore = namespace('LayoutStore');

@Component({
  name: 'Generator',
  components: {
    GtMenu,
    NewStructureModal,
    OldStructureModal,
    CheckoutNewSeriesModal,
    GeneratorMobileCurtain
  }
})
export default class Generator extends Vue {
  isNewStructureModalShown = false;
  isOldStructureModalShown = false;
  isSubjectModalShown = true;
  isMenuLoaded: boolean = false;
  isMobileCurtainShown: boolean = true;

  @AppStore.State('structureStatus') structureStatus: StructureStatusName;
  @LayoutStore.State('menu') menu: MenuModel;

  get categoryKey (): string {
    return this.$route.params.categoryKey.replace(/,/g, '/');
  }

  get newSeriesData (): NewSeriesMap | null {
    const newSeriesData = this.menu ? this.menu.newSeriesMap : null;
    return Array.isArray(newSeriesData) ? null : newSeriesData;
  }

  @Watch('categoryKey', { immediate: true })
  async categoryKeyHandler (): Promise<void> {
    this.isMenuLoaded = false;
    await this.setMenu(this.categoryKey);
    this.isMenuLoaded = true;
    let structureStatus: StructureStatusName = null;
    if (this.menu.mapCategory && this.menu.mapCategory.length > 0) {
      const activeClass = this.menu.class.find(item => item.class === 'active');
      structureStatus = activeClass && activeClass.newStructure ? StructureStatus.NEW : StructureStatus.OLD;
      this.isNewStructureModalShown = structureStatus === StructureStatus.NEW;
      this.isOldStructureModalShown = structureStatus === StructureStatus.OLD;
    }
    this.setStructureStatus(structureStatus);
    this.setIsUserLogged(Boolean(this.$cookies.get(CookieName.USER_TOKEN)));
  }

  @AppStore.Mutation(SET_STRUCTURE_STATUS) setStructureStatus: (structureStatus: StructureStatusName) => void;
  @AuthStore.Mutation(SET_IS_USER_LOGGED) setIsUserLogged: any;
  @LayoutStore.Action(SET_MENU) setMenu: any;

  hideMobileCurtain (): void {
    this.isMobileCurtainShown = false;
  }
}
